import React, { Component } from 'react';
import { Table} from 'react-bootstrap';
import { NavLink as Link} from "react-router-dom";
import Select from 'react-select';
import { DateRangePicker } from 'rsuite';
import {AsyncPaginate} from 'react-select-async-paginate';

import { callBackendAPI, setOrGetCookiesValue} from './../../helpers/common';
import { translate, formatDate, getDatesBetween, getCurrentWeekDates } from './../../helpers/intl_helpers';
import { STATUS_SUCCESS, DATE_RANGE_PICKER_FORMAT, DEPARTMENT_DROPDOWN, REPORT_DATE_FORMAT, EXPORT_CSV, EXPORT_PDF, CALENDAR_DATE_TIME_FORMAT, JOB_TITLE_TYPE_DROPDOWN, ACTIVE } from './../../config/global_constants';

import closeIcon 	from './../../assets/images/close_icon.svg';
import filterImg 	from './../../assets/images/filter.svg';
import loaderGif 	from './../../assets/images/pagination_loader.gif';

class ResourcesAvailability extends Component {

	constructor(props) {
		super(props);

		let startDate	=	getCurrentWeekDates().monday;
		let endDate		=	getCurrentWeekDates().friday;

		/** Get search stats from cookies  */
		let tmpSearchStats = {date_range :[startDate, endDate]};
		let tmpVal =  setOrGetCookiesValue('searchStatsResourcesAvailabilityReport');
		if(tmpVal && tmpVal.constructor === Object && Object.keys(tmpVal).length){
			/** Convert into date format */
			if(tmpVal.date_range && tmpVal.date_range.length){
				startDate	=	new Date(tmpVal.date_range[0]);
				endDate		=	new Date(tmpVal.date_range[1]);
				tmpVal.date_range = [startDate, endDate];
			}

			tmpSearchStats = tmpVal;
		}

        this.state = {
			defaultDateRange: 	[getCurrentWeekDates().monday, getCurrentWeekDates().friday],
			headerDateRange	: 	getDatesBetween(startDate, endDate),
			searchStats 	: 	tmpSearchStats,
            tableDataList	: 	[],
			isLoading		:	true,
			locationList	:	[],
        };

		this.getTableContent    	=	this.getTableContent.bind(this);
		this.exportTableContent		=	this.exportTableContent.bind(this);
		this.handleChangePage    	=	this.handleChangePage.bind(this);
		this.resetSearchStats    	=	this.resetSearchStats.bind(this);
		this.getDropDownList        =   this.getDropDownList.bind(this);
		this.getMasterList    		=	this.getMasterList.bind(this);
    }

	/** This function invoked immediately after a component is mounted.
	 * Its use to call initial function.
	 */
	componentDidMount() {
		this.getTableContent();
		this.getMasterList();
    }

	/**
	* For get master listing
	*/
	getMasterList = () => {
		window.showLoader();
		callBackendAPI([{ model: 'home', method: 'masterList' , type: ["location"]}]).then(response => {
			window.hideLoader();
			if(response.success && response.data[0].status === STATUS_SUCCESS && response.data[0].result) {
				this.setState({
					isLoading	: false,
					locationList: response.data[0].result.location
				});
			}
		}).catch(err => console.log(err));
	}

	/**
	* For get dropdown listing
	*/
    async getDropDownList(inputValue, loadedOptions, { page, type }) {
        try{
			let apiReq = { model: 'calendar', method: 'employeeList', emp_name: inputValue ? inputValue : ''};
            if(type === "crewMember")  apiReq.is_crew_member = true;
			if(type === "skill_set")  apiReq = { model: 'home', method: 'masterData', type: "skill_set", name: inputValue ? inputValue : ''};

			/** Add page number */
            apiReq.current_page = page;

			let response = await callBackendAPI([apiReq]);

			let resData 		= 	response && response.data && response.data[0] ? response.data[0] :{};
			let result 			=	resData.result ? resData.result :[];
			let recordsTotal 	=	resData.recordsTotal ? resData.recordsTotal:0;
			let completeArray 	=	result.concat(loadedOptions);

			return {
				options		:	result,
				hasMore		: 	completeArray.length  === recordsTotal ? false :true,
				additional	: 	{page: page + 1, type: type},
			}
		} catch (error) {
			console.error('Error loading options:', error);
			return {options: [], hasMore: true, additional: {page: page, type: type}};
		}
    }// end getDropDownList()

	/**
	* For update current page stats
	*
	* @param newPage as selected page number
	*
	* @return null
	*/
	handleChangePage = (newPage = 1,e) => {
		if(e) e.preventDefault();

		this.setState({currentPage: newPage},()=>{
			this.getTableContent();
		});
	};

	/**
	* For reset search stats
	*
	* @param null
	*
	* @return null
	*/
	resetSearchStats = () => {
		this.setState({searchStats: {date_range: this.state.defaultDateRange}, currentPage: 1 },()=>{
			this.getTableContent();
		});
	};

	/**
	* For update state fields values
	*
	* @param fieldName	as current field name
	* @param fieldVal  	as current field value
	*
	* @return null
	*/
	handleChange = (fieldName) => (fieldVal) => {
		let newObj = {[fieldName]: fieldVal};
		let updatedFields = {...this.state.searchStats, ...newObj};

		if(fieldName === "location" && fieldVal.length === 0) 	updatedFields['department'] = null;

		if (fieldName === "exclude_weekend") {
			updatedFields['exclude_weekend'] = fieldVal;  // fieldVal should be true or false
		}
		this.setState({searchStats: updatedFields});
	};

	/**
	* For manage table listing data
	*/
	getTableContent = () => {
		const { searchStats } = this.state;

		/** Set loading stats */
		this.setState({isLoading:true},()=>{

			/** Set api params */
			let tmpStartDate 	=	searchStats.date_range && searchStats.date_range[0] ? searchStats.date_range[0] :"";
			let tmpEndDate		= 	searchStats.date_range && searchStats.date_range[1] ? searchStats.date_range[1] :"";
			let apiReq 			=	{ model: 'reports', method: 'resourcesAvailability', start_date: formatDate(tmpStartDate,CALENDAR_DATE_TIME_FORMAT), end_date: formatDate(tmpEndDate,CALENDAR_DATE_TIME_FORMAT)};
			if(searchStats && Object.keys(searchStats).length){
				let locArr    = [];
                let deptArr   = [];
				let empArr 		= [];
				let skillSetArr = [];
				if(searchStats.location && searchStats.location.length) 	  	locArr	 = searchStats.location.map(element => element.value);
                if(searchStats.department && searchStats.department.length) 	deptArr  = searchStats.department.map(element => element.value);
				if(searchStats.employee_id && searchStats.employee_id.length)   empArr 		= searchStats.employee_id.map(element => element.value);
				if(searchStats.skill_set && searchStats.skill_set.length) skillSetArr = searchStats.skill_set.map(element => element.value);

				if(searchStats.location && searchStats.location.length)  		apiReq.location    	=   locArr;
                if(searchStats.department && searchStats.department.length)  	apiReq.department  	=   deptArr;
				if(searchStats.skill_set && searchStats.skill_set.length)       apiReq.skill_set_ids=   skillSetArr;
				if(searchStats.job_type && searchStats.job_type.value)  		apiReq.job_type  	=   searchStats.job_type.value;
				if(searchStats.employee_id && searchStats.employee_id.length)  	apiReq.employee_id  =   empArr;
				apiReq.exclude_weekend = searchStats.exclude_weekend ? searchStats.exclude_weekend : "";
			}

			/** Set search stats in cookies  */
			setOrGetCookiesValue('searchStatsResourcesAvailabilityReport', true, searchStats);

			/** get data table data list */
			callBackendAPI([apiReq]).then(response=>{
				if(response.success && response.data[0].status === STATUS_SUCCESS){
					this.setState({
						headerDateRange	: 	response.data[0].dateArray,
						tableDataList 	:	response.data[0].result,
						isLoading		: 	false
					});
				}
			}).catch(err => console.log(err));
		});
	};// end getTableContent()

	/**
	* For manage export event
	*/
	exportTableContent = (fileType) => {
		const {searchStats } = this.state;

		/** Set loading stats */
		window.showLoader();

		/** Set api params */
		let tmpStartDate 	=	searchStats.date_range && searchStats.date_range[0] ? searchStats.date_range[0] :"";
		let tmpEndDate		= 	searchStats.date_range && searchStats.date_range[1] ? searchStats.date_range[1] :"";
		let apiReq 			=	{model: 'reports', method: 'exportResourcesAvailability', file_type: fileType, start_date: formatDate(tmpStartDate,CALENDAR_DATE_TIME_FORMAT), end_date: formatDate(tmpEndDate,CALENDAR_DATE_TIME_FORMAT)};
		if(searchStats && Object.keys(searchStats).length){
			let locArr      = [];
			let deptArr     = [];
			let empArr 		= [];
			let skillSetArr = [];
			if(searchStats.location && searchStats.location.length) 	  	locArr	 	= searchStats.location.map(element => element.value);
			if(searchStats.department && searchStats.department.length) 	deptArr  	= searchStats.department.map(element => element.value);
			if(searchStats.employee_id && searchStats.employee_id.length)   empArr 		= searchStats.employee_id.map(element => element.value);
			if(searchStats.skill_set && searchStats.skill_set.length)       skillSetArr = searchStats.skill_set.map(element => element.value);

			if(searchStats.location && searchStats.location.length)  		apiReq.location    	=   locArr;
			if(searchStats.department && searchStats.department.length)  	apiReq.department  	=   deptArr;
			if(searchStats.skill_set && searchStats.skill_set.length)       apiReq.skill_set_ids=   skillSetArr;
			if(searchStats.job_type && searchStats.job_type.value)  		apiReq.job_type  	=   searchStats.job_type.value;
			if(searchStats.employee_id && searchStats.employee_id.length)  	apiReq.employee_id  =   empArr;
			apiReq.exclude_weekend = searchStats.exclude_weekend ? searchStats.exclude_weekend : "";
		}

		/** get data table data list */
		callBackendAPI([apiReq]).then(response=>{
			if(response.success && response.data[0].status === STATUS_SUCCESS){
				if(response.data[0].file_url){
					const link 		=	document.createElement('a');
					link.href 		=	response.data[0].file_url;
					link.rel 		=	'noopener noreferrer'; // Security for '_blank' links
					document.body.appendChild(link); // Required for Firefox
					link.click();
					document.body.removeChild(link);
				}

				/** Set loading stats */
				window.hideLoader();
			}
		}).catch(err => console.log(err));
	};// end exportTableContent()

	/**
	* For manage listing mid table html
	*/
	getTableBody  = (currentIndex, date)=>{
		const {tableDataList} = this.state;
		let html = "";
		if(tableDataList && tableDataList.length && date){
			tableDataList.forEach((row, rowIndex)=>{
				if(row.date && row.employee_list && row.employee_list.length && formatDate(row.date,REPORT_DATE_FORMAT) === formatDate(date, REPORT_DATE_FORMAT)){
					row.employee_list.forEach((data, dataIndex)=>{
						html += `<li key="row-${rowIndex+"-"+currentIndex+'-'+dataIndex}">${data.full_name}</li>`;
					});
				}
			});
		}
		return html ? "<ul>"+html+"</ul>" :"-";
	}

	/**
	* For update state fields values
	*
	* @param e  	as current field object
	* @param field  as current field name
	*
	* @return null
	*/
	handleFormInputs(e, field) {
		const { checked } = e.target;  // Get the checked value of the checkbox

		const updatedSearchStats = { ...this.state.searchStats, [field]: checked ? true : null };

		this.setState({
		  searchStats: updatedSearchStats,
		});
	}// end handleFormInputs()

	render() {
		const {tableDataList, searchStats, isLoading, defaultDateRange, headerDateRange, locationList} = this.state;

		return <>
			<div className="dashboard-content min-height-72vh">
				<div className="dashboard-heading d-flex justify-content-between align-items-center">
					<div className="left-heading">
						<h1>{translate("reports.resources_availability")}</h1>
					</div>
					<div className="right-button">
						<Link to="#" className="btn btn-fill btn-filter "  data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="collapseOne">
							<img src={filterImg} alt="Img" width={29} height={26} />
						</Link>
					</div>
				</div>
				<div className="filter-box">
					<div className="accordion" id="reportJobAccordionExample">
						<div className="accordion-item">
							<div id="reportJobCollapseOne" className="accordion-collapse collapse show" data-bs-parent="#reportJobAccordionExample">
								<div className="accordion-body">
									<div className="filter-box-header d-flex justify-content-between mb-3">
										<h3 className="mb-0">{translate("employee.filter")}</h3>
										<Link to="#" className="btn-filter-close" data-bs-toggle="collapse" data-bs-target="#reportJobCollapseOne" aria-expanded="true" aria-controls="reportJobCollapseOne">
											<img src={closeIcon} alt="Img" width={20} height={20} />
										</Link>
									</div>
									<form className="adduser-form" onSubmit={(e)=>{ this.handleChangePage(1,e)}}>
										<div className="row">
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="date_range">{translate('reports.date')}</label>
													<div className="col-12">
														<DateRangePicker
															value={searchStats.date_range ? searchStats.date_range :null}
															format={DATE_RANGE_PICKER_FORMAT}
															size="lg"
															className='form-control input-with-label'
															editable={false}
															onChange={this.handleChange("date_range")}
															placeholder={translate('reports.search_by_date')}
															onClean={()=>{ this.setState({searchStats: {...searchStats,...{date_range: defaultDateRange}}}) }}
														/>
													</div>
												</div>
											</div>
											<div className="col-lg-4">
												<div className='form-group'>
													<label className='mb-1 form-label' htmlFor="search_by_location">{translate('reports.location')}</label>
													<Select
														className="custom-react-select"
														inputId="search_by_location"
														value={searchStats["location"] ? searchStats["location"] :null}
														options={locationList}
														onChange={this.handleChange("location")}
														closeMenuOnSelect={true}
														isClearable={true}
														isMulti
														placeholder={translate('reports.search_by_location')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_department">{translate('reports.department')}</label>
													<Select
														className="custom-react-select"
														options={(searchStats.location && Array.isArray(searchStats.location)) ? DEPARTMENT_DROPDOWN.filter(dept =>
															searchStats.location.some(loc => loc.department && loc.department.includes(dept.value))
														) : []}
														closeMenuOnSelect={true}
														isMulti
														placeholder={translate("calendar.all_department")}
														value={searchStats.department ? searchStats.department :null}
														onChange={this.handleChange("department")}
														isClearable={true}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_employee">{translate('reports.employee')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_by_employee"
														value={searchStats.employee_id  ? searchStats.employee_id : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("employee_id")}
														additional={{ page: 1, type: "crewMember"}}
														isClearable={true}
														isMulti
														placeholder={translate('reports.search_by_employee')}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_by_job_type">{translate('reports.search_by_job_type')}</label>
													<Select
														className="custom-react-select"
														options={JOB_TITLE_TYPE_DROPDOWN}
														closeMenuOnSelect={true}
														placeholder={translate("calendar.all_job_type")}
														value={searchStats.job_type ? searchStats.job_type :null}
														onChange={this.handleChange("job_type")}
														isClearable={true}
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className='mb-1 form-label' htmlFor="search_skill_set">{translate('reports.skill_set')}</label>
													<AsyncPaginate
														className="custom-react-select"
														inputId="search_skill_set"
														value={searchStats.skill_set  ? searchStats.skill_set : null}
														loadOptions={this.getDropDownList}
														onChange={this.handleChange("skill_set")}
														additional={{ page: 1, type: "skill_set"}}
														isClearable={true}
														placeholder={translate('reports.all_skill_set')}
														isMulti
													/>
												</div>
											</div>
											<div className="col-lg-4">
												<div className="form-group">
													<label className="mb-1 form-label" htmlFor="other">{translate('reports.exclude_weekend')}</label>
													<div className="form-group checkbox-row style-2 order-checkbox-row mt-0 mt-lg-3">
														<div className="form-check form-check-inline custom-checkbox">
															<input
																className="form-check-input"
																type="checkbox"
																name="exclude_weekend"
																id="exclude_weekend"
																checked={searchStats.exclude_weekend === true}
																onChange={(e) => this.handleFormInputs(e, 'exclude_weekend')}
															/>
															<label className="form-check-label" htmlFor="exclude_weekend">
															{translate('reports.exclude_weekend')}
															</label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-12 d-flex">
												<button className="btn btn-fill me-3 width-height-initial" type="submit" onClick={(e)=>{ this.handleChangePage(1,e) }} >{translate("system.submit")}</button>
												<button className="btn btn-outline" onClick={() => this.resetSearchStats()}>{translate("system.reset")}</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="users-list bg-transparent p-0">
					<div className="row">
						<div className="col-lg-12 text-end">
							<div className="form-group export-btns">
								<Link to="#" className="btn btn-fill me-2 btn-sm" onClick={()=>{this.exportTableContent(EXPORT_PDF)}}>{translate("reports.export_as_pdf")}</Link>
								<Link to="#" className="btn btn-fill" onClick={()=>{this.exportTableContent(EXPORT_CSV)}}>{translate("reports.export_as_csv")}</Link>
							</div>
						</div>
					</div>
					<div className="table-responsive theme-table">
						<Table className="employee-table-list" bordered responsive>
							<thead>
								<tr>
									<th width="20%">{translate("reports.date")}</th>
									<th>{translate("job_report.employees")}</th>
								</tr>
							</thead>
							<tbody>
								{isLoading ?
									<tr>
										<td colSpan="2" className='text-center'>
											<img src={loaderGif} alt="loading-icon" />
										</td>
									</tr>
								: !tableDataList.length ?
									<tr>
										<td colSpan="2" className='text-center'>
											{translate("datatable.no_records_found")}
										</td>
									</tr>
								:headerDateRange.map((date, index)=>
									<tr key={"row"+index}>
										<td width="20%"> {formatDate(date, REPORT_DATE_FORMAT)} </td>
										<td>
											<span dangerouslySetInnerHTML={{__html: this.getTableBody(index, date)}}></span>
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
				</div>
			</div>
		</>
	}
}
export default ResourcesAvailability;
