import 'antd/lib/popover/style/index.css';

import React, {Component} from 'react'
import {PropTypes} from 'prop-types'
import Popover from 'antd/lib/popover'
import EventItemPopover from './EventItemPopover'
import {CellUnits, DATETIME_FORMAT} from './index'
import {DnDTypes} from './DnDTypes'
import {SHIFT_DAY} from '../../config/global_constants'
import nightIcon from '../../assets/images/moon_stars_icon.svg';
import dayIcon from '../../assets/images/sun_icon.svg';
import copyIcon from '../../assets/images/copy_icon.svg';
import editIcon from '../../assets/images/edit_mode_icon.svg';
import deleteIcon from '../../assets/images/delete-icon.svg';
import deleteIconWithWhite from '../../assets/images/delete-icon-white.svg';
import boxRemoveIcon from '../../assets/images/box-remove-Icon.svg';
import kebabIcon from '../../assets/images/three_dot_icon.svg';
import { UserDetails }  from '../../UserDetails';

import { callBackendAPI, reFormatSuccess, customConfirmBox, convertTo12HourFormat, manageJobAction } from '../../helpers/common';
import { STATUS_SUCCESS, CALENDAR_PTO, CALENDAR_YARD, CALENDAR_DATE_TIME_FORMAT, CALENDAR_SUB_CONTRACTOR, ADMIN, DISPATCHER, FOREMAN } from '../../config/global_constants'
import { formatDate, translate } from '../../helpers/intl_helpers'

const supportTouch = 'ontouchstart' in window;

class EventItem extends Component {
    static contextType = UserDetails;

    constructor(props) {
        super(props);

        const {left, top, width} = props;
        this.state = {
            left: left,
            top: top,
            width: width,
            isBtnBoxVisible: false
        };
        this.startResizer = null;
        this.endResizer = null;
    }

    static propTypes = {
        schedulerData: PropTypes.object.isRequired,
        eventItem: PropTypes.object.isRequired,
        isStart: PropTypes.bool.isRequired,
        isEnd: PropTypes.bool.isRequired,
        left: PropTypes.number.isRequired,
        width: PropTypes.number.isRequired,
        top: PropTypes.number.isRequired,
        isInPopover: PropTypes.bool.isRequired,
        leftIndex: PropTypes.number.isRequired,
        rightIndex: PropTypes.number.isRequired,
        isDragging: PropTypes.bool.isRequired,
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func.isRequired,
        updateEventStart: PropTypes.func,
        updateEventEnd: PropTypes.func,
        moveEvent: PropTypes.func,
        subtitleGetter: PropTypes.func,
        eventItemClick: PropTypes.func,
        viewEventClick: PropTypes.func,
        viewEventText: PropTypes.string,
        viewEvent2Click: PropTypes.func,
        viewEvent2Text: PropTypes.string,
        conflictOccurred: PropTypes.func,
        eventItemTemplateResolver: PropTypes.func,
        isMonthView: PropTypes.bool,
    }

    UNSAFE_componentWillReceiveProps(np) {
        const {left, top, width} = np;
        this.setState({
            left: left,
            top: top,
            width: width,
        });
        this.subscribeResizeEvent(np);
    }

    componentDidMount() {
        this.subscribeResizeEvent(this.props);
    }

    initStartDrag = (ev) => {
        const {schedulerData, eventItem} = this.props;
        let slotId = schedulerData._getEventSlotId(eventItem);
        let slot = schedulerData.getSlotById(slotId);
        if(!!slot && !!slot.groupOnly) return;
        if(schedulerData._isResizing()) return;

        ev.stopPropagation();
        let clientX = 0;
        if(supportTouch) {
            if(ev.changedTouches.length === 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            if (ev.buttons !== undefined && ev.buttons !== 1) return;
            clientX = ev.clientX;
        }
        this.setState({
            startX: clientX
        });
        schedulerData._startResizing();

        if(supportTouch) {
            this.startResizer.addEventListener('touchmove', this.doStartDrag, false);
            this.startResizer.addEventListener('touchend', this.stopStartDrag, false);
            this.startResizer.addEventListener('touchcancel', this.cancelStartDrag, false);
        } else {
            document.documentElement.addEventListener('mousemove', this.doStartDrag, false);
            document.documentElement.addEventListener('mouseup', this.stopStartDrag, false);
        }
        document.onselectstart = function () {
			return false;
		};
		document.ondragstart = function () {
			return false;
		};
    }

    doStartDrag = (ev) => {
        ev.stopPropagation();

        let clientX = 0;
        if(supportTouch) {
            if(ev.changedTouches.length === 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const {left, width, leftIndex, rightIndex, schedulerData} = this.props;
        let cellWidth = schedulerData.getContentCellWidth();
        let offset = leftIndex > 0 ? 5 : 6;
        let minWidth = cellWidth - offset;
        let maxWidth = rightIndex * cellWidth - offset;
        const {startX} = this.state;
        let newLeft = left + clientX - startX;
        let newWidth = width + startX - clientX;
        if (newWidth < minWidth) {
            newWidth = minWidth;
            newLeft = (rightIndex - 1) * cellWidth + (rightIndex - 1 > 0 ? 2 : 3);
        }
        else if (newWidth > maxWidth) {
            newWidth = maxWidth;
            newLeft = 3;
        }

        this.setState({left: newLeft, width: newWidth});
    }

    stopStartDrag = (ev) => {
        ev.stopPropagation();
        if(supportTouch) {
            this.startResizer.removeEventListener('touchmove', this.doStartDrag, false);
            this.startResizer.removeEventListener('touchend', this.stopStartDrag, false);
            this.startResizer.removeEventListener('touchcancel', this.cancelStartDrag, false);
        } else {
            document.documentElement.removeEventListener('mousemove', this.doStartDrag, false);
            document.documentElement.removeEventListener('mouseup', this.stopStartDrag, false);
        }
        document.onselectstart = null;
        document.ondragstart = null;
        const {width, left, top, leftIndex, rightIndex, schedulerData, eventItem, updateEventStart, conflictOccurred} = this.props;
        schedulerData._stopResizing();
        if(this.state.width === width) return;

        let clientX = 0;
        if(supportTouch) {
            if(ev.changedTouches.length === 0) {
                this.setState({
                    left: left,
                    top: top,
                    width: width,
                });
                return;
            }
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const {cellUnit, events, config, localeMoment} = schedulerData;
        let cellWidth = schedulerData.getContentCellWidth();
        let offset = leftIndex > 0 ? 5 : 6;
        let minWidth = cellWidth - offset;
        let maxWidth = rightIndex * cellWidth - offset;
        const {startX} = this.state;
        let newWidth = width + startX - clientX;
        let deltaX = clientX - startX;
        let sign = deltaX < 0 ? -1 : (deltaX === 0 ? 0 : 1);
        let count = (sign > 0 ? Math.floor(Math.abs(deltaX) / cellWidth) : Math.ceil(Math.abs(deltaX) / cellWidth)) * sign;
        if (newWidth < minWidth)
            count = rightIndex - leftIndex - 1;
        else if (newWidth > maxWidth)
            count = -leftIndex;
        let newStart = localeMoment(eventItem.start).add(cellUnit === CellUnits.Hour ? count * config.minuteStep : count, cellUnit === CellUnits.Hour ? 'minutes' : 'days').format(DATETIME_FORMAT);
        if(count !== 0 && cellUnit !== CellUnits.Hour && config.displayWeekend === false) {
            if(count > 0) {
                let tempCount = 0, i = 0;
                while (true) {
                    i++;
                    let tempStart = localeMoment(eventItem.start).add(i, 'days');
                    let dayOfWeek = tempStart.weekday();
                    if(dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount ++;
                        if(tempCount === count) {
                            newStart = tempStart.format(DATETIME_FORMAT);
                            break;
                        }
                    }

                }
            } else {
                let tempCount = 0, i = 0;
                while (true) {
                    i--;
                    let tempStart = localeMoment(eventItem.start).add(i, 'days');
                    let dayOfWeek = tempStart.weekday();
                    if(dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount --;
                        if(tempCount === count) {
                            newStart = tempStart.format(DATETIME_FORMAT);
                            break;
                        }
                    }
                }
            }
        }

        let hasConflict = false;
        let slotId = schedulerData._getEventSlotId(eventItem);
        let slotName = undefined;
        let slot = schedulerData.getSlotById(slotId);
        if(!!slot)
            slotName = slot.name;
        if (config.checkConflict) {
            let start = localeMoment(newStart),
                end = localeMoment(eventItem.end);

            events.forEach((e) => {
                if (schedulerData._getEventSlotId(e) === slotId && e.id !== eventItem.id) {
                    let eStart = localeMoment(e.start),
                        eEnd = localeMoment(e.end);
                    if ((start >= eStart && start < eEnd) || (end > eStart && end <= eEnd) || (eStart >= start && eStart < end) || (eEnd > start && eEnd <= end))
                        hasConflict = true;
                }
            });
        }

        if (hasConflict) {
            this.setState({
                left: left,
                top: top,
                width: width,
            });

            if (conflictOccurred !== undefined) {
                conflictOccurred(schedulerData, 'StartResize', eventItem, DnDTypes.EVENT, slotId, slotName, newStart, eventItem.end);
            }
            else {
                console.log('Conflict occurred, set conflictOccurred func in Scheduler to handle it');
            }
            this.subscribeResizeEvent(this.props);
        }
        else {
            if (updateEventStart !== undefined)
                updateEventStart(schedulerData, eventItem, newStart);
        }
    }

    cancelStartDrag = (ev) => {
        ev.stopPropagation();

        this.startResizer.removeEventListener('touchmove', this.doStartDrag, false);
        this.startResizer.removeEventListener('touchend', this.stopStartDrag, false);
        this.startResizer.removeEventListener('touchcancel', this.cancelStartDrag, false);
        document.onselectstart = null;
        document.ondragstart = null;
        const {schedulerData, left, top, width} = this.props;
        schedulerData._stopResizing();
        this.setState({
            left: left,
            top: top,
            width: width,
        });
    }

    initEndDrag = (ev) => {
        const {schedulerData, eventItem} = this.props;
        let slotId = schedulerData._getEventSlotId(eventItem);
        let slot = schedulerData.getSlotById(slotId);
        if(!!slot && !!slot.groupOnly) return;
        if(schedulerData._isResizing()) return;

        ev.stopPropagation();
        let clientX = 0;
        if(supportTouch) {
            if(ev.changedTouches.length === 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            if (ev.buttons !== undefined && ev.buttons !== 1) return;
            clientX = ev.clientX;
        }
        this.setState({
            endX: clientX
        });

        schedulerData._startResizing();
        if(supportTouch) {
            this.endResizer.addEventListener('touchmove', this.doEndDrag, false);
            this.endResizer.addEventListener('touchend', this.stopEndDrag, false);
            this.endResizer.addEventListener('touchcancel', this.cancelEndDrag, false);
        } else {
            document.documentElement.addEventListener('mousemove', this.doEndDrag, false);
            document.documentElement.addEventListener('mouseup', this.stopEndDrag, false);
        }
        document.onselectstart = function () {
			return false;
		};
		document.ondragstart = function () {
			return false;
		};
    }

    doEndDrag = (ev) => {
        ev.stopPropagation();
        let clientX = 0;
        if(supportTouch) {
            if(ev.changedTouches.length === 0) return;
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const {width, leftIndex, schedulerData} = this.props;
        const {headers} = schedulerData;
        let cellWidth = schedulerData.getContentCellWidth();
        let offset = leftIndex > 0 ? 5 : 6;
        let minWidth = cellWidth - offset;
        let maxWidth = (headers.length - leftIndex) * cellWidth - offset;
        const {endX} = this.state;

        let newWidth = (width + clientX - endX);
        if (newWidth < minWidth)
            newWidth = minWidth;
        else if (newWidth > maxWidth)
            newWidth = maxWidth;

        this.setState({width: newWidth});
    }

    stopEndDrag = (ev) => {
        ev.stopPropagation();

        if(supportTouch) {
            this.endResizer.removeEventListener('touchmove', this.doEndDrag, false);
            this.endResizer.removeEventListener('touchend', this.stopEndDrag, false);
            this.endResizer.removeEventListener('touchcancel', this.cancelEndDrag, false);
        } else {
            document.documentElement.removeEventListener('mousemove', this.doEndDrag, false);
            document.documentElement.removeEventListener('mouseup', this.stopEndDrag, false);
        }
        document.onselectstart = null;
        document.ondragstart = null;
        const {width, left, top, leftIndex, rightIndex, schedulerData, eventItem, updateEventEnd, conflictOccurred} = this.props;
        schedulerData._stopResizing();
        if(this.state.width === width) return;

        let clientX = 0;
        if(supportTouch) {
            if(ev.changedTouches.length === 0) {
                this.setState({
                    left: left,
                    top: top,
                    width: width,
                });
                return;
            }
            const touch = ev.changedTouches[0];
            clientX = touch.pageX;
        } else {
            clientX = ev.clientX;
        }
        const {headers, cellUnit, events, config, localeMoment} = schedulerData;
        let cellWidth = schedulerData.getContentCellWidth();
        let offset = leftIndex > 0 ? 5 : 6;
        let minWidth = cellWidth - offset;
        let maxWidth = (headers.length - leftIndex) * cellWidth - offset;
        const {endX} = this.state;

        let newWidth = (width + clientX - endX);
        let deltaX = newWidth - width;
        let sign = deltaX < 0 ? -1 : (deltaX === 0 ? 0 : 1);
        let count = (sign < 0 ? Math.floor(Math.abs(deltaX) / cellWidth) : Math.ceil(Math.abs(deltaX) / cellWidth)) * sign;
        if (newWidth < minWidth)
            count = leftIndex - rightIndex + 1;
        else if (newWidth > maxWidth)
            count = headers.length - rightIndex;
        let newEnd = localeMoment(eventItem.end).add(cellUnit === CellUnits.Hour ? count * config.minuteStep : count, cellUnit === CellUnits.Hour ? 'minutes' : 'days').format(DATETIME_FORMAT);
        if(count !== 0 && cellUnit !== CellUnits.Hour && config.displayWeekend === false) {
            if(count > 0) {
                let tempCount = 0, i = 0;
                while (true) {
                    i++;
                    let tempEnd = localeMoment(eventItem.end).add(i, 'days');
                    let dayOfWeek = tempEnd.weekday();
                    if(dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount ++;
                        if(tempCount === count) {
                            newEnd = tempEnd.format(DATETIME_FORMAT);
                            break;
                        }
                    }

                }
            } else {
                let tempCount = 0, i = 0;
                while (true) {
                    i--;
                    let tempEnd = localeMoment(eventItem.end).add(i, 'days');
                    let dayOfWeek = tempEnd.weekday();
                    if(dayOfWeek !== 0 && dayOfWeek !== 6) {
                        tempCount --;
                        if(tempCount === count) {
                            newEnd = tempEnd.format(DATETIME_FORMAT);
                            break;
                        }
                    }
                }
            }
        }

        let hasConflict = false;
        let slotId = schedulerData._getEventSlotId(eventItem);
        let slotName = undefined;
        let slot = schedulerData.getSlotById(slotId);
        if(!!slot)
            slotName = slot.name;
        if (config.checkConflict) {
            let start = localeMoment(eventItem.start),
                end = localeMoment(newEnd);

            events.forEach((e) => {
                if (schedulerData._getEventSlotId(e) === slotId && e.id !== eventItem.id) {
                    let eStart = localeMoment(e.start),
                        eEnd = localeMoment(e.end);
                    if ((start >= eStart && start < eEnd) || (end > eStart && end <= eEnd) || (eStart >= start && eStart < end) || (eEnd > start && eEnd <= end))
                        hasConflict = true;
                }
            });
        }

        if (hasConflict) {
            this.setState({
                left: left,
                top: top,
                width: width,
            });

            if (conflictOccurred !== undefined) {
                conflictOccurred(schedulerData, 'EndResize', eventItem, DnDTypes.EVENT, slotId, slotName, eventItem.start, newEnd);
            }
            else {
                console.log('Conflict occurred, set conflictOccurred func in Scheduler to handle it');
            }
            this.subscribeResizeEvent(this.props);
        }
        else {
            if (updateEventEnd !== undefined)
                updateEventEnd(schedulerData, eventItem, newEnd);
        }
    }

    cancelEndDrag = (ev) => {
        ev.stopPropagation();

        this.endResizer.removeEventListener('touchmove', this.doEndDrag, false);
        this.endResizer.removeEventListener('touchend', this.stopEndDrag, false);
        this.endResizer.removeEventListener('touchcancel', this.cancelEndDrag, false);
        document.onselectstart = null;
        document.ondragstart = null;
        const {schedulerData, left, top, width} = this.props;
        schedulerData._stopResizing();
        this.setState({
            left: left,
            top: top,
            width: width,
        });
    }

    /**Handle onclick function for remove asset/crew members from job */
    handleRemoveItem = (event,itemId,type,e) =>{
        e.stopPropagation();

        let confirmation = customConfirmBox(translate("calendar.you_want_to_unassign"));
        confirmation.then((confirmed) => {
            if (confirmed) {
                /** Set API request params */
                let apiReq = [
                    { model: 'calendar', method: 'unassignMemberOrAssetOnJob', foreman_id: event.foreman_id, job_id: event.id, [(type === "asset") ? "asset_id": "crew_member_id" ]: itemId, type: type },
                ]
                callBackendAPI(apiReq).then(response => {
                    if(response.success && response.data[0].status === STATUS_SUCCESS) {
                        this.props.updateEventCalendar(null,"calendar");
                        reFormatSuccess(response.data[0].message)
                    }
                }).catch(err => console.log(err));
            }
        });
    }// end handleRemoveItem()

    /**Handle onclick function for remove job */
    handleRemoveJob = (e,jobId) =>{
        e.stopPropagation();

        let confirmation = customConfirmBox(translate("calendar.you_want_to_remove_this_job"));
        confirmation.then((confirmed) => {
            if (confirmed) {
                /** Set API request params */
                let apiReq = [ { model: 'jobs', method: 'deleteJob', job_id: jobId},]
                callBackendAPI(apiReq).then(response => {
                    if(response.success && response.data[0].status === STATUS_SUCCESS) {
                        this.props.updateEventCalendar(null,"calendar");
                        reFormatSuccess(response.data[0].message)
                    }
                }).catch(err => console.log(err));
            }
        });
    }// end handleRemoveItem()

    toggleJobBtns = () => {
        this.setState(prevState => ({
            isBtnBoxVisible: !prevState.isBtnBoxVisible
        }));
    };

    handleClick = (e) => {
        // Handle the click event here, if necessary
        // For example, you could open a modal, navigate, etc.
        e.preventDefault(); // If you want to prevent the default behavior (e.g., scrolling)
    };

    render() {
        const userDetails = this.context;

        const {eventItem, isStart, isEnd, eventItemClick, schedulerData, isDragging, connectDragSource, connectDragPreview, eventItemTemplateResolver, isMonthView, openEditModal} = this.props;
        const {config} = schedulerData;
        const {left, width, isBtnBoxVisible} = this.state;
        let resourceId      =   eventItem && eventItem.resourceId ? eventItem.resourceId :"";
        let isSubcontractor =   (resourceId === CALENDAR_SUB_CONTRACTOR)? true :false;
        let isPTO           =   (resourceId === CALENDAR_PTO)           ? true :false;
        let isDisableForeman=   (eventItem && eventItem.is_disable)     ? true :false;
        let roleId          =   userDetails.userDetails._id;
        let rootJobId       =   eventItem._id ? eventItem._id :"";

        let roundCls = isStart ? (isEnd ? 'round-all' : 'round-head') : (isEnd ? 'round-tail' : 'round-none');
        let bgColor = config.defaultEventBgColor;
        if (!!eventItem.bgColor)
            bgColor = eventItem.bgColor;

        let isReadOnly      = schedulerData.is_read_only;
        let permissionLevel = schedulerData.permission_level;
        let isDeleteTimeOff = false;
        if(permissionLevel === ADMIN || permissionLevel === DISPATCHER) isDeleteTimeOff = true;

        let content = (
            <EventItemPopover
                {...this.props}
                eventItem={eventItem}
                image={eventItem.image}
                title={eventItem.title}
                startTime={eventItem.start}
                endTime={eventItem.end}
                statusColor={bgColor}/>
        );
        let eventDate   = formatDate(eventItem.date,CALENDAR_DATE_TIME_FORMAT);
        let startResizeDiv = <div />;

        if (this.startResizable(this.props))
            startResizeDiv = <div className="event-resizer event-start-resizer" ref={(ref) => this.startResizer = ref}></div>;
        let endResizeDiv = <div />;
        if (this.endResizable(this.props))
            endResizeDiv = <div className="event-resizer event-end-resizer" ref={(ref) => this.endResizer = ref}></div>;

        let tableKey = eventItem.id+Math.random();

        let eventItemTemplate = (
            <div className={roundCls + ' event-item'} key={tableKey}>
                {isMonthView && (
                    <div className="row" style={{ maxHeight: eventItem.maxHeight+"px !important" }} onClick={() => { if(eventItemClick && !isSubcontractor) eventItemClick(schedulerData, eventItem);}}>
                        {isSubcontractor ?
                            <div className='row crew-members' >
                                {eventItem.crewMembers && eventItem.crewMembers.length ? (
                                    eventItem.crewMembers.map((val, index) => (
                                        <div className='col-12 w-100' key={val._id+index+tableKey}>
                                            <div className={(val.termination_date) ? "terminate-emp" : ""} style={{marginLeft: '20px', backgroundColor: val.bgColor ? val.bgColor : "#fff", lineHeight: "`${config.eventItemHeight}px`"  }}>
                                                <img src={val.image} style={{ maxWidth: "40px", maxHeight: "40px", borderRadius: "40%" }} alt="crew-img" onClick={() => { if(eventItemClick && isSubcontractor && val.root_id) eventItemClick(schedulerData, {_id: val.root_id});}}  />
                                                <span onClick={() => { if(eventItemClick && isSubcontractor && val.root_id) eventItemClick(schedulerData, {_id: val.root_id});}}
                                                    dangerouslySetInnerHTML={{__html: val.groupName }} >
                                                </span>
                                                {val.root_id ?
                                                    <button className="box-remove-btn" onClick={(e)=> {this.handleRemoveJob(e,val.root_id)}}>
                                                        <img src={deleteIconWithWhite} alt="img"/>
                                                    </button>
                                                :null}
                                            </div>
                                        </div>
                                    ))
                                ): null}
                            </div>
                        :
                            <p className='text-primary' dangerouslySetInnerHTML={{__html:eventItem.title}}></p>
                        }
                    </div>)
                }
                {!isMonthView && (
                    <div className="row" onClick={() => { if(eventItemClick && !isSubcontractor) eventItemClick(schedulerData, eventItem);}}>
                        {eventItem.title ? <p className='text-primary' dangerouslySetInnerHTML={{__html:eventItem.title}}></p> :null}

                        <div className='row crew-members' >
                            {eventItem.crewMembers && eventItem.crewMembers.length ? (
                                eventItem.crewMembers.map((val, index) => (
                                    <div className='col-sm-6' key={val._id+index+tableKey}>
                                        <div className={(val.termination_date) ? "terminate-emp" : ""} style={{marginLeft: '20px', backgroundColor: val.bgColor ? val.bgColor : "#fff", lineHeight: "`${config.eventItemHeight}px`"  }}>
                                            <img src={val.image} style={{ maxWidth: "40px", maxHeight: "40px", borderRadius: "40%" }} alt="crew-img" onClick={() => { if(eventItemClick && isSubcontractor && val.root_id) eventItemClick(schedulerData, {_id: val.root_id});}}  />
                                            <span onClick={() => { if(eventItemClick && isSubcontractor && val.root_id) eventItemClick(schedulerData, {_id: val.root_id});}} dangerouslySetInnerHTML={{__html: val.groupName }}>
                                            </span>
                                            {(!isSubcontractor && manageJobAction(eventDate).emp_delete && !isReadOnly ) &&
                                                <button className="box-remove-btn" onClick={(e)=> {this.handleRemoveItem(eventItem,val.id,"crew",e)}}>
                                                    <img src={boxRemoveIcon} alt="img"/>
                                                </button>
                                            }
                                            {isSubcontractor && val.root_id ?
                                                <button className="box-remove-btn" onClick={(e)=> {this.handleRemoveJob(e,val.root_id)}}>
                                                    <img src={deleteIconWithWhite} alt="img"/>
                                                </button>
                                            :null}
                                            {(isPTO && (permissionLevel === FOREMAN) && roleId === val.id) &&
                                                <button className="box-remove-btn" onClick={(e)=> {this.handleRemoveItem(eventItem,val.id,"crew",e)}}>
                                                    <img src={boxRemoveIcon} alt="img"/>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                ))
                            ): null}
                        </div>

                        <div className='row assets'>
                            {eventItem.assets && eventItem.assets.length ? (
                                eventItem.assets.map((val, index) => (
                                    <div className='col-sm-6' key={val._id+index+tableKey}>
                                        <div className={(!val.is_active) ? "terminate-emp" : ""} style={{marginLeft: '20px', lineHeight: "`${config.eventItemHeight}px`", backgroundColor: val.bgColor ? val.bgColor : "#fff" }}>

                                            <img src={val.image} style={{ maxWidth: "40px", maxHeight: "40px", borderRadius: "40%" }} alt="" />
                                            <p>
                                                <span>{val.groupName}</span>
                                                {val.driver_owner_name ? <small>({val.driver_owner_name})</small> : null}
                                            </p>
                                            {(!isSubcontractor && manageJobAction(eventDate).equip_delete && !isReadOnly) &&
                                                <button className="box-remove-btn" onClick={(e)=> {this.handleRemoveItem(eventItem,val.id,"asset",e)}}>
                                                    <img src={boxRemoveIcon} alt="img"/>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                ))
                            ) : null}
                        </div>
                    </div>)
                }

                {permissionLevel !== FOREMAN && (
                    !isMonthView && !isDisableForeman && !isSubcontractor && (
                    <div className="job-edit-btns">
                        <button
                        className={`kebab-edit-btn kebab-edit-btn-${rootJobId}`}
                        onClick={() => this.toggleJobBtns()}
                        >
                        <img src={kebabIcon} alt="kebab icon" />
                        </button>

                        <div className={`d-flex align-items-center job-btns-box ${isBtnBoxVisible ? 'show' : ''}`}>
                        {!isReadOnly && (
                            <>
                            {manageJobAction(eventDate).clone && (
                                <button
                                type="button"
                                className="btns copy-btn"
                                onClick={() => openEditModal && openEditModal(eventItem.foreman_id, rootJobId, true)}
                                aria-label="Copy Job"
                                >
                                <img src={copyIcon} alt="Copy icon" />
                                </button>
                            )}

                            {manageJobAction(eventDate).edit && (
                                <button
                                type="button"
                                className="btns edit-btn"
                                onClick={() => openEditModal && openEditModal(eventItem.foreman_id, rootJobId)}
                                aria-label="Edit Job"
                                >
                                <img src={editIcon} alt="Edit icon" />
                                </button>
                            )}
                            </>
                        )}

                        {(isPTO && !isDeleteTimeOff) ? null : (
                            <button
                            type="button"
                            className="btns delete-btn"
                            onClick={(e) => this.handleRemoveJob(e, rootJobId)}
                            >
                            <img src={deleteIcon} alt="Delete icon" />
                            </button>
                        )}
                        </div>
                    </div>
                    )
                )}

                {/**Set condition for show icon basis on month/week view */}
                {!isMonthView && !isPTO && !isSubcontractor ?
                    <>
                        {eventItem.shift && eventItem.shift === SHIFT_DAY ?
                            <div className="shifts-icon">
                                <figure>
                                    <img src={dayIcon} alt="img" />
                                </figure>
                                <span>{eventItem && eventItem.start_time ? convertTo12HourFormat(eventItem.start_time) : ""}</span>
                            </div>
                        : eventItem.shift ?
                            <div className="shifts-icon">
                                <figure>
                                    <img src={nightIcon} alt="img" />
                                </figure>
                                <span>{eventItem && eventItem.start_time ? convertTo12HourFormat(eventItem.start_time) : ""}</span>
                            </div>
                        :
                            null
                        }
                    </>
                :null}
                {isMonthView && !isPTO && !isSubcontractor ?
                    <>
                        {eventItem.shift && eventItem.shift === SHIFT_DAY ?
                            <div className="shifts-icon">
                                <figure>
                                    <img src={dayIcon} alt="img" />
                                </figure>
                                <span>{eventItem && eventItem.start_time ? convertTo12HourFormat(eventItem.start_time) : ""}</span>
                            </div>
                        : eventItem.shift ?
                            <div className="shifts-icon">
                                <figure>
                                    <img src={nightIcon} alt="img" />
                                </figure>
                                <span>{eventItem && eventItem.start_time ? convertTo12HourFormat(eventItem.start_time) : ""}</span>
                            </div>
                        :
                        null}
                    </>
                : null}
                {isMonthView && !isSubcontractor && eventItem.shift ?
                    <>
                        {eventItem.shift === SHIFT_DAY ?
                            <div className="month-shift-icon">
                                <figure>
                                    <img src={dayIcon} alt="img" />
                                </figure>
                            </div>
                        : <div className="month-shift-icon">
                                <figure>
                                    <img src={nightIcon} alt="img" />
                                </figure>
                            </div>
                        }
                    </>
                :null}
            </div>
        );

        bgColor = eventItem.job_group_color;

        /** Set condition for calendar pto */
        if(isPTO) bgColor = "#fff";
        if(!isSubcontractor && resourceId !== CALENDAR_YARD && !isPTO && eventItem.is_draft)
            bgColor = "#f8d7da";
        if(isDisableForeman)
            bgColor = "#ddd";

        // if(eventItemTemplateResolver !== undefined)
        //     eventItemTemplate = eventItemTemplateResolver(schedulerData, eventItem, bgColor, isStart, isEnd, 'event-item', config.eventItemHeight, undefined);
        let monthViewClass   =  isMonthView   ? "month-view"  :"";

        let a = <a href='#' className= {"timeline-event " + monthViewClass + " " + ((eventItem.is_draft) ? "draft-shift" : ((!isDisableForeman && eventItem.shift === SHIFT_DAY) || isPTO) ? "day-shift" : isDisableForeman ? "disable-foreman" : (!eventItem.shift ? "day-shift" : "night-shift"))} dataob={eventItem.start+"-"+rootJobId} style={{left: left, width: width, top: eventItem.total_height}} datajobid={rootJobId} onClick={this.handleClick} >
            {eventItemTemplate}
            {startResizeDiv}
            {endResizeDiv}
            {(eventItem && eventItem.fiber_task_name) ? <div className='fiber_task_text'>{eventItem.fiber_task_name}</div>: ""}
        </a>;

        return (
            isDragging ? null : ( schedulerData._isResizing() || config.eventItemPopoverEnabled === false || eventItem.showPopover === false ?
                    <>
                        {
                            connectDragPreview(
                                connectDragSource(a)
                            )
                        }
                    </>:
                    <Popover placement="bottomLeft" content={content} trigger="hover">
                        {
                            connectDragPreview(
                                connectDragSource(a)
                            )
                        }
                    </Popover>
            )
        );
    }

    startResizable = (props) => {
        const {eventItem, isInPopover, schedulerData} = props;
        const {config} = schedulerData;
        return config.startResizable === true && isInPopover === false
            && (eventItem.resizable === undefined || eventItem.resizable !== false)
            && (eventItem.startResizable === undefined || eventItem.startResizable !== false);
    }

    endResizable = (props) => {
        const {eventItem, isInPopover, schedulerData} = props;
        const {config} = schedulerData;
        return config.endResizable === true && isInPopover === false
            && (eventItem.resizable === undefined || eventItem.resizable !== false)
            && (eventItem.endResizable === undefined || eventItem.endResizable !== false);
    }

    subscribeResizeEvent = (props) => {
        if (this.startResizer != undefined) {
            if(supportTouch) {
                // this.startResizer.removeEventListener('touchstart', this.initStartDrag, false);
                // if (this.startResizable(props))
                //     this.startResizer.addEventListener('touchstart', this.initStartDrag, false);
            } else {
                this.startResizer.removeEventListener('mousedown', this.initStartDrag, false);
                if (this.startResizable(props))
                    this.startResizer.addEventListener('mousedown', this.initStartDrag, false);
            }
        }
        if (this.endResizer != undefined) {
            if(supportTouch) {
                // this.endResizer.removeEventListener('touchstart', this.initEndDrag, false);
                // if (this.endResizable(props))
                //     this.endResizer.addEventListener('touchstart', this.initEndDrag, false);
            } else {
                this.endResizer.removeEventListener('mousedown', this.initEndDrag, false);
                if (this.endResizable(props))
                    this.endResizer.addEventListener('mousedown', this.initEndDrag, false);
            }
        }
    }
}

export default EventItem